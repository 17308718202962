// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topup-amountTextField_input-D0niP::-webkit-outer-spin-button,
.topup-amountTextField_input-D0niP::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.topup-amountTextField_input-D0niP {
  -moz-appearance: textfield;
  appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/components/topup/topup.module.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,0BAA0B;EAC1B,qBAAqB;AACvB","sourcesContent":[".amountTextField_input::-webkit-outer-spin-button,\n.amountTextField_input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.amountTextField_input {\n  -moz-appearance: textfield;\n  appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amountTextField_input": `topup-amountTextField_input-D0niP`
};
export default ___CSS_LOADER_EXPORT___;
