import React from 'react';
import { Text, Brand } from '@recargas-dominicanas/core/components';
import { style } from './not-found.module.css';
export function NotFound() {
    return (React.createElement("div", { className: style.container },
        React.createElement(Brand, { style: style.brand }),
        React.createElement("div", null,
            React.createElement(Text, { className: style.error, text: '404' }),
            React.createElement(Text, { className: style.message, text: 'Pagina no encontrada' }))));
}
