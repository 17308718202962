// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-title-title_container-ScBIz {
  padding: 16px 20px 8px;
}

.product-card-title-title_title-Y_2Jh {
  flex-shrink: 0;
  font-size: 17px;
  font-weight: 500;
  color: rgb(0 0 0 / 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/components/product-card/product-card-title/product-card-title.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".title_container {\n  padding: 16px 20px 8px;\n}\n\n.title_title {\n  flex-shrink: 0;\n  font-size: 17px;\n  font-weight: 500;\n  color: rgb(0 0 0 / 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title_container": `product-card-title-title_container-ScBIz`,
	"title_title": `product-card-title-title_title-Y_2Jh`
};
export default ___CSS_LOADER_EXPORT___;
