// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-container-von0C {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  flex: 0 0 360px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/product-card/product-card.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,qCAAqC;EACrC,kBAAkB;AACpB","sourcesContent":[".container {\n  min-height: 200px;\n  display: flex;\n  flex-direction: column;\n  flex: 0 0 360px;\n  border: 1px solid var(--border-color);\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `product-card-container-von0C`
};
export default ___CSS_LOADER_EXPORT___;
