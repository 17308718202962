import React, { Fragment } from 'react';
import { expired, formatCurrency, formatRef } from '@recargas-dominicanas/core/utils';
import { DetailModal } from '../detail-modal/detail-modal.component';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { ModalContent, OutlineButton, ModalOptions } from '@recargas-dominicanas/core/components';
export function InvoiceModal(props) {
    var open = props.open, title = props.title, transaction = props.transaction, overlayEnterAnimation = props.overlayEnterAnimation, onPrint = props.onPrint, onCancel = props.onCancel, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, title: title, overlayEnterAnimation: overlayEnterAnimation, onClose: onClose },
        React.createElement(ModalContent, null, transaction &&
            React.createElement(Fragment, null,
                React.createElement(DetailModalItem, { title: 'Compa\u00F1\u00EDa', text: transaction.product.name }),
                React.createElement(DetailModalItem, { title: 'No. de contrato', text: transaction.contract.nic }),
                React.createElement(DetailModalItem, { title: 'Titular', text: transaction.contract.name }),
                React.createElement(DetailModalItem, { title: 'Monto', text: formatCurrency(transaction.amount), color: 'green' }),
                React.createElement(DetailModalItem, { title: 'Beneficio', text: formatCurrency(transaction.profit), color: 'green' }),
                React.createElement(DetailModalItem, { title: 'Referencia', text: formatRef(transaction.reference) }),
                transaction.cancelled &&
                    React.createElement(DetailModalItem, { title: 'Estado', text: 'Cancelada' }))),
        React.createElement(ModalOptions, null,
            onPrint &&
                React.createElement(OutlineButton, { icon: 'receipt', text: 'Imprimir', onClick: onPrint }),
            transaction && onCancel && !transaction.cancelled && !expired(transaction) &&
                React.createElement(OutlineButton, { icon: 'cancel', text: 'Cancelar', onClick: onCancel }))));
}
