import React from 'react';
import { formatCurrency, formatPhone } from '@recargas-dominicanas/core/utils';
import { DetailModal } from '../detail-modal/detail-modal.component';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { ModalContent, ModalActions, Button, OutlineButton } from '@recargas-dominicanas/core/components';
export function TopupConfirmModal(props) {
    var open = props.open, company = props.company, phone = props.phone, amount = props.amount, overlayEnterAnimation = props.overlayEnterAnimation, onAccept = props.onAccept, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, title: 'Confirmar recarga', overlayEnterAnimation: overlayEnterAnimation },
        React.createElement(ModalContent, null,
            React.createElement(DetailModalItem, { title: 'Compa\u00F1\u00EDa', text: company }),
            React.createElement(DetailModalItem, { title: 'Tel\u00E9fono', text: formatPhone(phone) }),
            React.createElement(DetailModalItem, { title: 'Monto', text: formatCurrency(amount), color: 'green' })),
        React.createElement(ModalActions, null,
            React.createElement(Button, { text: 'Cancelar', onClick: onClose }),
            React.createElement(OutlineButton, { text: 'Aceptar', onClick: onAccept }))));
}
