export function print(lines) {
    var width = parseInt(localStorage.getItem('ticketWidth') || '40');
    var endLines = parseInt(localStorage.getItem('endLines') || '5');
    var ticket = lines.map(function (line) { return typeof line === 'string' ? line : line(width); }).join('');
    var hideFrame = document.createElement('iframe');
    hideFrame.style.display = 'none';
    hideFrame.onload = function () {
        var closePrint = function () { return document.body.removeChild(hideFrame); };
        hideFrame.contentWindow.onbeforeunload = closePrint;
        hideFrame.contentWindow.onafterprint = closePrint;
        hideFrame.contentWindow.print();
    };
    hideFrame.srcdoc =
        '<html>' +
            '<body>' +
            '<pre>' +
            ticket +
            ''.padStart(endLines, ' \n') +
            '' +
            '</pre>' +
            '</body>' +
            '</html>';
    document.body.appendChild(hideFrame);
}
export function left(text) {
    return function (width) { return text.padStart(width, ' '); };
}
export function center(text) {
    return function (width) {
        var diff = (width - text.length) / 2;
        return ''.padStart(diff) + text + ''.padEnd(diff) + '\n';
    };
}
export function newLine() {
    return function () { return ' \n'; };
}
export function line() {
    return function (width) {
        return ''.padStart(width, '-') + '\n';
    };
}
export function colums(col1, col2) {
    return function (width) {
        var spaces = width - (col1.length + col2.length);
        return col1 + col2.padStart(spaces + col2.length) + '\n';
    };
}
