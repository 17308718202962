var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import { TransactionApi } from '@recargas-dominicanas/core/api';
import { formatCurrency, useForm, validators } from '@recargas-dominicanas/core/utils';
import { useBusiness, useSalesReport, useTransactions } from '@recargas-dominicanas/core/store';
import { DataPlanConfirmModal } from '../data-plan-confirm-modal/data-plan-confirm-modal.component';
import { DataPlanModal } from '../data-plan-modal/data-plan-modal.component';
import { ErrorModal } from '../error-modal/error-modal.component';
import { printDataPlan } from '../../utils/printDataPlan';
import { style } from './data-plan.module.css';
import { TextField, LoadingModal, Select, SelectOption, Icon } from '@recargas-dominicanas/core/components';
import { ProductCard, ProductCardButtons, ProductCardContent, ProductCardFields, ProductCardInfo, ProductCardTitle } from '../product-card';
export function DataPlan(props) {
    var product = props.product;
    var businessStore = useBusiness();
    var salesReports = useSalesReport();
    var transactions = useTransactions();
    var business = businessStore.get();
    var _a = useState(), transaction = _a[0], setTransaction = _a[1];
    var _b = useState(), dataPlans = _b[0], setDataPlans = _b[1];
    var _c = useState(true), disableDataPlansSelect = _c[0], setDisableDataPlansSelect = _c[1];
    var _d = useState(false), showErrorMessage = _d[0], setShowErrorMessage = _d[1];
    var _e = useState(false), openConfirmModal = _e[0], setOpenConfirmModal = _e[1];
    var _f = useState(false), openLoadingModal = _f[0], setOpenLoadingModal = _f[1];
    var _g = useState(false), openSuccessfulModal = _g[0], setOpenSuccessfulModal = _g[1];
    var _h = useState(false), openOutstandingBalanceModal = _h[0], setOpenOutstandingBalanceModal = _h[1];
    var _j = useState(false), openInsufficientFundsModal = _j[0], setOpenInsufficientFundsModal = _j[1];
    var _k = useState(false), openUnknownErrorModal = _k[0], setOpenUnknownErrorModal = _k[1];
    var form = useForm({
        phone: '',
        dataPlan: undefined
    });
    useEffect(function () {
        form.clear();
        setDataPlans(undefined);
        setDisableDataPlansSelect(true);
        setShowErrorMessage(false);
    }, [product.id]);
    function handlePhoneChange(value) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var _c, err_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        (_a = form.fields.dataPlan) === null || _a === void 0 ? void 0 : _a.clear();
                        setDataPlans(undefined);
                        setShowErrorMessage(false);
                        setDisableDataPlansSelect(true);
                        if (!(value.length >= 10)) return [3 /*break*/, 4];
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        setDisableDataPlansSelect(false);
                        _c = setDataPlans;
                        return [4 /*yield*/, TransactionApi.getDataPlans(product.id, value)];
                    case 2:
                        _c.apply(void 0, [_d.sent()]);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _d.sent();
                        (_b = form.fields.dataPlan) === null || _b === void 0 ? void 0 : _b.clear();
                        setDisableDataPlansSelect(true);
                        setShowErrorMessage(true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleConfirm() {
        if (form.isValid()) {
            setOpenConfirmModal(true);
        }
    }
    function handleSend() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var transaction_1, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setOpenConfirmModal(false);
                        setOpenLoadingModal(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, TransactionApi.sendDataPlan(product.id, form.value.phone, form.value.dataPlan.id)];
                    case 2:
                        transaction_1 = _b.sent();
                        salesReports.fetchCurrent();
                        transactions.fetchGroupByDay(1, 50);
                        setOpenLoadingModal(false);
                        setTransaction(transaction_1);
                        setOpenSuccessfulModal(true);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _b.sent();
                        setOpenLoadingModal(false);
                        if (((_a = err_2 === null || err_2 === void 0 ? void 0 : err_2.response) === null || _a === void 0 ? void 0 : _a.data) === 'INSUFFICIENT_FUNDS')
                            return [2 /*return*/, setOpenInsufficientFundsModal(true)];
                        if (err_2.response.data === 'OUTSTANDING_BALANCE')
                            return [2 /*return*/, setOpenOutstandingBalanceModal(true)];
                        setOpenUnknownErrorModal(true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handleCloseSuccessfulModal() {
        setOpenSuccessfulModal(false);
        setTransaction(undefined);
        setDataPlans(undefined);
        setDisableDataPlansSelect(true);
        form.clear();
    }
    function handleCancel() {
        form.clear();
        setDataPlans(undefined);
        setDisableDataPlansSelect(true);
        setShowErrorMessage(false);
    }
    return (React.createElement(ProductCard, null,
        React.createElement(ProductCardTitle, { title: 'Paquetico' }),
        React.createElement(ProductCardContent, null,
            React.createElement(ProductCardInfo, { product: product }),
            React.createElement(ProductCardFields, null,
                React.createElement(TextField, { formField: form.fields.phone, type: 'tel', label: 'Tel\u00E9fono', onChange: handlePhoneChange, validators: [
                        validators.required,
                        validators.phone
                    ] }),
                React.createElement(Select, { style: style.select, label: 'Plan de datos', required: true, disabled: disableDataPlansSelect, formField: form.fields.dataPlan },
                    !dataPlans &&
                        React.createElement(CircularProgress, { className: style.circularProgress, size: 44 }), dataPlans === null || dataPlans === void 0 ? void 0 :
                    dataPlans.map(function (plan) {
                        return React.createElement(SelectOption, { key: plan.id, style: style.selectOption, value: plan, showClearIcon: false, label: plan.name },
                            React.createElement("div", { className: style.dataPlanItem },
                                React.createElement("span", null, plan.name),
                                React.createElement("span", { className: style.price }, formatCurrency(plan.price))));
                    }))),
            showErrorMessage &&
                React.createElement("div", { className: style.dataPlansError },
                    React.createElement(Icon, { className: style.dataPlansErrorIcon, icon: 'info' }),
                    React.createElement("div", null,
                        React.createElement("div", { className: style.dataPlansErrorTitle }, "No se encontraron planes de datos"),
                        React.createElement("div", { className: style.dataPlansErrorDescription }, "Verifique el n\u00FAmero de tel\u00E9fono y la compa\u00F1\u00EDa e intentelo denuevo m\u00E1s tarde.")))),
        React.createElement(ProductCardButtons, { onAccept: handleConfirm, onCancel: handleCancel }),
        React.createElement(DataPlanConfirmModal, { open: openConfirmModal, company: product.name, phone: form.value.phone, dataPlan: form.value.dataPlan, onAccept: handleSend, onClose: function () { return setOpenConfirmModal(false); } }),
        React.createElement(LoadingModal, { title: 'Realizando operaci\u00F3n', open: openLoadingModal }),
        React.createElement(DataPlanModal, { open: openSuccessfulModal, title: 'Paquetico exitoso', dataPlan: form.value.dataPlan, transaction: transaction, onPrint: function () { return printDataPlan(business, transaction, form.value.dataPlan); }, onClose: handleCloseSuccessfulModal }),
        React.createElement(ErrorModal, { open: openInsufficientFundsModal, title: 'No posee suficiente balance', description: 'No posee suficiente balance para realizar esta operaci\u00F3n.', onClose: function () { return setOpenInsufficientFundsModal(false); } }),
        React.createElement(ErrorModal, { open: openOutstandingBalanceModal, title: 'Cliente con prestamo', description: 'No se puede realizar la operaci\u00F3n hasta que el prestamo este pago. Intente con un monto mayor.', onClose: function () { return setOpenOutstandingBalanceModal(false); } }),
        React.createElement(ErrorModal, { open: openUnknownErrorModal, title: 'Ocurrio un error', description: 'Intentelo denuevo m\u00E1s tarde.', onClose: function () { return setOpenUnknownErrorModal(false); } })));
}
