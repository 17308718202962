import React, { Fragment, memo, useState } from 'react';
import { useBusiness } from '@recargas-dominicanas/core/store';
import { TransactionItem } from '@recargas-dominicanas/core/components';
import { DataPlanModal } from '../data-plan-modal/data-plan-modal.component';
import { printDataPlan } from '../../utils/printDataPlan';
import { style } from './data-plan-item.module.css';
export var DataPlanItem = memo(function DataPlanItem(props) {
    var transaction = props.transaction;
    var business = useBusiness().get();
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    return (React.createElement(Fragment, null,
        React.createElement(TransactionItem, { style: style.item, key: transaction.id, showMenu: false, transaction: transaction, onClick: function () { return setOpenModal(true); } }),
        React.createElement(DataPlanModal, { open: openModal, title: 'Paquetico', transaction: transaction, onPrint: function () { return printDataPlan(business, transaction, undefined); }, onClose: function () { return setOpenModal(false); } })));
}, propsAreEqual);
function propsAreEqual(prev, next) {
    return (prev.transaction.id === next.transaction.id &&
        prev.transaction.cancelled === next.transaction.cancelled);
}
