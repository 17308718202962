import React, { Fragment } from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { DetailModal } from '../detail-modal/detail-modal.component';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { ModalContent, ModalActions, Button, OutlineButton } from '@recargas-dominicanas/core/components';
export function InvoiceConfirmModal(props) {
    var open = props.open, company = props.company, invoice = props.invoice, onAccept = props.onAccept, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, title: 'Datos de facturaci\u00F3n', overlayEnterAnimation: false },
        React.createElement(ModalContent, null, invoice &&
            React.createElement(Fragment, null,
                React.createElement(DetailModalItem, { title: 'Compa\u00F1\u00EDa', text: company }),
                React.createElement(DetailModalItem, { title: 'No. de contrato', text: invoice.nic }),
                React.createElement(DetailModalItem, { title: 'Titular', text: invoice.name }),
                React.createElement(DetailModalItem, { title: 'Monto', text: formatCurrency(invoice.amount), color: 'green' }))),
        React.createElement(ModalActions, null,
            React.createElement(Button, { text: 'Cancelar', onClick: onClose }),
            React.createElement(OutlineButton, { text: 'Pagar', onClick: onAccept }))));
}
