// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-container-izH5n {
  padding: 0px 32px 20px;
}

.transactions-toolbar-t_Hko {
  height: 52px;
  align-items: center;
}

.transactions-title_title-ls9Vq {
  font-size: 17px;
}

.transactions-content-Rlaam {
  margin-top: 0px;
}

.transactions-outlineCard-HixLV {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.transactions-tableContainer-ogwpa {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.transactions-table_container-X5dKE {
  padding-left: 16px;
  padding-right: calc(16px - var(--scroll-width));
  overflow-y: scroll;
}

.transactions-tableHeader_cellContent-Qm9hL {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.transactions-error-kBSDZ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`, "",{"version":3,"sources":["webpack://./src/pages/transactions/transactions.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,+CAA+C;EAC/C,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC","sourcesContent":[".container {\n  padding: 0px 32px 20px;\n}\n\n.toolbar {\n  height: 52px;\n  align-items: center;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.content {\n  margin-top: 0px;\n}\n\n.outlineCard {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.tableContainer {\n  flex-grow: 1;\n  position: relative;\n  overflow: hidden;\n}\n\n.table_container {\n  padding-left: 16px;\n  padding-right: calc(16px - var(--scroll-width));\n  overflow-y: scroll;\n}\n\n.tableHeader_cellContent {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.error {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `transactions-container-izH5n`,
	"toolbar": `transactions-toolbar-t_Hko`,
	"title_title": `transactions-title_title-ls9Vq`,
	"content": `transactions-content-Rlaam`,
	"outlineCard": `transactions-outlineCard-HixLV`,
	"tableContainer": `transactions-tableContainer-ogwpa`,
	"table_container": `transactions-table_container-X5dKE`,
	"tableHeader_cellContent": `transactions-tableHeader_cellContent-Qm9hL`,
	"error": `transactions-error-kBSDZ`
};
export default ___CSS_LOADER_EXPORT___;
