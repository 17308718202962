import React, { Fragment, useRef, useState } from 'react';
import { TableRow, Cell, CellIcon, Menu, Option } from '@recargas-dominicanas/core/components';
import { formatDate, formatCurrency } from '@recargas-dominicanas/core/utils';
import { printSalesReport } from '../../utils/printSalesReport';
import { useBusiness } from '@recargas-dominicanas/core/store';
export function SalesReportRow(props) {
    var salesReport = props.salesReport;
    var business = useBusiness().get();
    var anchor = useRef(null);
    var _a = useState(false), openMenu = _a[0], setOpenMenu = _a[1];
    return (React.createElement(Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(Cell, { text: formatDate(salesReport.date) }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.balance) }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.sales) }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.sales - salesReport.profit) }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(salesReport.profit) }),
            React.createElement(CellIcon, { icon: 'more_horiz', refIcon: anchor, onClick: function () { return setOpenMenu(function (state) { return !state; }); } })),
        React.createElement(Menu, { open: openMenu, anchor: anchor, top: 4, onClose: function () { return setOpenMenu(false); } },
            React.createElement(Option, { text: 'Reimprimir', hiddeIcon: true, onClick: function () { return printSalesReport(business, salesReport); } }))));
}
