import React, { Fragment, memo, useState } from 'react';
import { useBusiness } from '@recargas-dominicanas/core/store';
import { TransactionItem } from '@recargas-dominicanas/core/components';
import { PinModal } from '../pin-modal/pin-modal.component';
import { printPin } from '../../utils/printPin';
import { style } from './pin-item.module.css';
export var PinItem = memo(function PinItem(props) {
    var transaction = props.transaction;
    var business = useBusiness().get();
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    return (React.createElement(Fragment, null,
        React.createElement(TransactionItem, { style: style.item, key: transaction.id, transaction: transaction, showMenu: false, onClick: function () { return setOpenModal(true); } }),
        React.createElement(PinModal, { open: openModal, title: 'Pin', transaction: transaction, onPrint: function () { return printPin(business, transaction); }, onClose: function () { return setOpenModal(false); } })));
}, propsAreEqual);
function propsAreEqual(prev, next) {
    return (prev.transaction.id === next.transaction.id &&
        prev.transaction.cancelled === next.transaction.cancelled);
}
