// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-content-container-vJyfY {
  flex-grow: 1;
  overflow: auto;
  padding: 0px 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/product-card/product-card-content/product-card-content.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".container {\n  flex-grow: 1;\n  overflow: auto;\n  padding: 0px 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `product-card-content-container-vJyfY`
};
export default ___CSS_LOADER_EXPORT___;
