// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-modal-modal_card-U3nwV {
  width: 312px;
  min-height: initial;
  margin: 16px;
}

.confirm-modal-modalToolbar_container-ZuQok {
  border-bottom: none;
}

.confirm-modal-modalToolbar_title-DFgxn {
  color: rgba(0, 0, 0, 0.9);
  font-size: 17px;
}

.confirm-modal-modalContent-YXvP5 {
  font-weight: 300;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 8px;
}

.confirm-modal-actionButton_base-uad0u {
  height: 36px; 
}
`, "",{"version":3,"sources":["webpack://./src/components/confirm-modal/confirm-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".modal_card {\n  width: 312px;\n  min-height: initial;\n  margin: 16px;\n}\n\n.modalToolbar_container {\n  border-bottom: none;\n}\n\n.modalToolbar_title {\n  color: rgba(0, 0, 0, 0.9);\n  font-size: 17px;\n}\n\n.modalContent {\n  font-weight: 300;\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.9);\n  margin-bottom: 8px;\n}\n\n.actionButton_base {\n  height: 36px; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `confirm-modal-modal_card-U3nwV`,
	"modalToolbar_container": `confirm-modal-modalToolbar_container-ZuQok`,
	"modalToolbar_title": `confirm-modal-modalToolbar_title-DFgxn`,
	"modalContent": `confirm-modal-modalContent-YXvP5`,
	"actionButton_base": `confirm-modal-actionButton_base-uad0u`
};
export default ___CSS_LOADER_EXPORT___;
