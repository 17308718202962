import React from 'react';
import { ProductItem, Title } from '@recargas-dominicanas/core/components';
import { style } from './products.module.css';
import { useProducts } from '@recargas-dominicanas/core/store';
export function Products(props) {
    var onClick = props.onClick;
    var products = useProducts().get();
    var topups = products.filter(function (product) { return product.type === 'Recarga'; });
    var dataPlans = products.filter(function (product) { return product.type === 'Paquetico'; });
    var pins = products.filter(function (product) { return product.type === 'Pin'; });
    var invoices = products.filter(function (product) { return product.type === 'Factura'; });
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.content },
            React.createElement(Title, { style: style.title, title: 'Recargas' }),
            React.createElement("div", { className: style.products }, topups.map(function (product) { return (React.createElement(ProductItem, { style: style.productItem, key: product.id, product: product, onClick: function () { return onClick(product); } })); }))),
        React.createElement("div", { className: style.content },
            React.createElement(Title, { style: style.title, title: 'Paqueticos' }),
            React.createElement("div", { className: style.products }, dataPlans.map(function (product) { return (React.createElement(ProductItem, { style: style.productItem, key: product.id, product: product, onClick: function () { return onClick(product); } })); }))),
        React.createElement("div", { className: style.content },
            React.createElement(Title, { style: style.title, title: 'Pines' }),
            React.createElement("div", { className: style.products }, pins.map(function (product) { return (React.createElement(ProductItem, { style: style.productItem, key: product.id, product: product, onClick: function () { return onClick(product); } })); }))),
        React.createElement("div", { className: style.content },
            React.createElement(Title, { style: style.title, title: 'Servicios' }),
            React.createElement("div", { className: style.products }, invoices.map(function (product) { return (React.createElement(ProductItem, { style: style.productItem, key: product.id, product: product, onClick: function () { return onClick(product); } })); })))));
}
