import clsx from 'clsx';
import React, { forwardRef } from 'react';
import warningIcon from '../../images/warning.svg';
import { style } from './error.module.css';
import { Button } from '@recargas-dominicanas/core/components';
export var Error = forwardRef(function Error(props, ref) {
    var className = props.className, onClick = props.onClick;
    return (React.createElement("div", { ref: ref, className: clsx(style.container, className) },
        React.createElement("img", { className: style.image, src: warningIcon }),
        React.createElement("div", { className: style.title }, "Ocurrio un error al cargar los datos"),
        React.createElement(Button, { text: 'Reintentar', onClick: onClick })));
});
