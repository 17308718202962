// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-buttons-buttons-xEATS {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 16px 20px;
}

.product-card-buttons-button_base-YMNrw {
  width: 136px;
  height: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/components/product-card/product-card-buttons/product-card-buttons.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd","sourcesContent":[".buttons {\n  display: flex;\n  flex-shrink: 0;\n  justify-content: space-between;\n  padding: 16px 20px;\n}\n\n.button_base {\n  width: 136px;\n  height: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `product-card-buttons-buttons-xEATS`,
	"button_base": `product-card-buttons-button_base-YMNrw`
};
export default ___CSS_LOADER_EXPORT___;
