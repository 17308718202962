// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container-y2XnD {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-brand_container-tLNrl {
  margin: 100px 0px;
}

.not-found-error-TujZ_ {
  font-size: 92px;
  font-weight: 500;
  color: var(--accent-color);
  text-align: center;
}

.not-found-message-mhTyu {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/not-found/not-found.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.brand_container {\n  margin: 100px 0px;\n}\n\n.error {\n  font-size: 92px;\n  font-weight: 500;\n  color: var(--accent-color);\n  text-align: center;\n}\n\n.message {\n  font-size: 22px;\n  font-weight: 500;\n  text-align: center;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `not-found-container-y2XnD`,
	"brand_container": `not-found-brand_container-tLNrl`,
	"error": `not-found-error-TujZ_`,
	"message": `not-found-message-mhTyu`
};
export default ___CSS_LOADER_EXPORT___;
