// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-item-item_background-e7B_Y:hover {
  background-color:  var(--hover-color);
}

.invoice-item-item_background-e7B_Y:active {
  background-color:  var(--active-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/invoice-item/invoice-item.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;AACvC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".item_background:hover {\n  background-color:  var(--hover-color);\n}\n\n.item_background:active {\n  background-color:  var(--active-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item_background": `invoice-item-item_background-e7B_Y`
};
export default ___CSS_LOADER_EXPORT___;
