// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-container-pf73l {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.empty-image-ASPjK {
  width: 80px;
  height: 80px;
}

.empty-title-tfnZy {
  font-size: 16px;
  margin-top: 12px;
}

.empty-description-Io502 {
  font-weight: 300;
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/empty/empty.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".container {\n  width: 300px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.image {\n  width: 80px;\n  height: 80px;\n}\n\n.title {\n  font-size: 16px;\n  margin-top: 12px;\n}\n\n.description {\n  font-weight: 300;\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `empty-container-pf73l`,
	"image": `empty-image-ASPjK`,
	"title": `empty-title-tfnZy`,
	"description": `empty-description-Io502`
};
export default ___CSS_LOADER_EXPORT___;
