var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useAsyncEffect, useForm, validators } from '@recargas-dominicanas/core/utils';
import { BusinessUserApi } from '@recargas-dominicanas/core/api';
import { Title, Text, TextField, OutlineButton, Error, Icon, Brand } from '@recargas-dominicanas/core/components';
import { style } from './create-password.module.css';
export function CreatePassword() {
    var _this = this;
    var history = useHistory();
    var form = useForm({ password: '', repeatPassword: '' });
    var _a = useState(), businessUser = _a[0], setBusinessUser = _a[1];
    var _b = useState(false), showError = _b[0], setShowError = _b[1];
    var token = useParams().token;
    var _c = useState(false), showPassword = _c[0], setShowPassword = _c[1];
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    _a = setBusinessUser;
                    return [4 /*yield*/, BusinessUserApi.validateToken(token)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _b.sent();
                    history.push('/page-not-found');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); });
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setShowError(false);
                        if (!form.isValid())
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, BusinessUserApi.createPassword(token, form.value.password)];
                    case 2:
                        _a.sent();
                        history.push('/');
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        setShowError(true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    if (!businessUser)
        return null;
    return (React.createElement("div", { className: style.container },
        React.createElement(Brand, { style: style.brand }),
        React.createElement(Text, { className: style.title, text: "!Bienvenido a Recargas Dominicanas <span>".concat(businessUser.name, "!</span>") }),
        React.createElement(Text, { className: style.description, text: 'Cree una nueva contrase\u00F1a para iniciar sesion' }),
        React.createElement("div", { className: style.card },
            React.createElement(Title, { title: 'Crear nueva contrase\u00F1a' }),
            React.createElement("div", { className: style.info },
                React.createElement(Icon, { className: style.icon, icon: 'storefront' }),
                React.createElement(Text, { className: style.name, text: businessUser.business.name }),
                React.createElement(Text, { className: style.username, text: businessUser.userName })),
            React.createElement(TextField, { style: style.newPassword, type: showPassword ? 'text' : 'password', formField: form.fields.password, placeholder: 'Nueva contrase\u00F1a', validators: [
                    validators.required,
                    validators.length(8, 'La contraseña debe tener al menos 8 caracteres')
                ] }),
            React.createElement(TextField, { style: style.repeatPassword, type: showPassword ? 'text' : 'password', formField: form.fields.repeatPassword, placeholder: 'Repetir contrase\u00F1a', validators: [
                    validators.required,
                    validators.length(8, 'La contraseña debe tener al menos 8 caracteres'),
                    validators.equal(form.value.password, 'Las contraseñas deben ser iguales')
                ] }),
            React.createElement("label", { className: style.showPassword },
                React.createElement("input", { type: 'checkbox', checked: showPassword, className: style.checkbox, onChange: function () { return setShowPassword(function (showPassword) { return !showPassword; }); } }),
                React.createElement("div", null, "Mostrar contrase\u00F1a")),
            React.createElement(Error, { show: showError, message: 'Ocurrio un error al crear la contrase\u00F1a' }),
            React.createElement(OutlineButton, { style: style.button, text: 'Crear contrase\u00F1a', onClick: handleSubmit }))));
}
