// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-modal-modal_card-YIWp7 {
  width: 312px;
  min-height: initial;
}

.detail-modal-toolbar_container-uDwmw {
  border-bottom: none;
}

.detail-modal-toolbar_title-Riueq {
  color: rgba(0, 0, 0, 0.9);
  font-size: 17px;
}
`, "",{"version":3,"sources":["webpack://./src/components/detail-modal/detail-modal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".modal_card {\n  width: 312px;\n  min-height: initial;\n}\n\n.toolbar_container {\n  border-bottom: none;\n}\n\n.toolbar_title {\n  color: rgba(0, 0, 0, 0.9);\n  font-size: 17px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_card": `detail-modal-modal_card-YIWp7`,
	"toolbar_container": `detail-modal-toolbar_container-uDwmw`,
	"toolbar_title": `detail-modal-toolbar_title-Riueq`
};
export default ___CSS_LOADER_EXPORT___;
