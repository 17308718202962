var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { BusinessUserApi } from '@recargas-dominicanas/core/api';
import { TextField, LoadingModal } from '@recargas-dominicanas/core/components';
import { formatCurrency, useForm, validators } from '@recargas-dominicanas/core/utils';
import { useBusiness, useSalesReport, useTransactions } from '@recargas-dominicanas/core/store';
import { TopupConfirmModal } from '../topup-confirm-modal/topup-confirm-modal.component';
import { TopupModal } from '../topup-modal/topup-modal.component';
import { ErrorModal } from '../error-modal/error-modal.component';
import { printTopup } from '../../utils/printToup';
import { style } from './topup.module.css';
import { ProductCard, ProductCardButtons, ProductCardContent, ProductCardFields, ProductCardInfo, ProductCardTitle } from '../product-card';
export function Topup(props) {
    var product = props.product;
    var businessStore = useBusiness();
    var salesReports = useSalesReport();
    var transactions = useTransactions();
    var business = businessStore.get();
    var form = useForm({ phone: '', amount: '' });
    var _a = useState(), transaction = _a[0], setTransaction = _a[1];
    var _b = useState(false), openConfirmModal = _b[0], setOpenConfirmModal = _b[1];
    var _c = useState(false), openLoadingModal = _c[0], setOpenLoadingModal = _c[1];
    var _d = useState(false), openSuccessfulModal = _d[0], setOpenSuccessfulModal = _d[1];
    var _e = useState(false), openInvalidPhoneModal = _e[0], setOpenInvalidPhoneModal = _e[1];
    var _f = useState(false), openInsufficientFundsModal = _f[0], setOpenInsufficientFundsModal = _f[1];
    var _g = useState(false), openWrongCompanyModal = _g[0], setOpenWrongCompanyModal = _g[1];
    var _h = useState(false), openDuplicatedModal = _h[0], setOpenDuplicatedModal = _h[1];
    var _j = useState(false), openUnknownErrorModal = _j[0], setOpenUnknownErrorModal = _j[1];
    useEffect(function () {
        form.clear();
    }, [product.id]);
    function handleConfirm() {
        if (form.isValid()) {
            setOpenConfirmModal(true);
        }
    }
    function handleSend() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var transaction_1, err_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        setOpenConfirmModal(false);
                        setOpenLoadingModal(true);
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, BusinessUserApi.sendTransaction(product.id, form.value.phone, parseInt(form.value.amount))];
                    case 2:
                        transaction_1 = _e.sent();
                        form.clear();
                        salesReports.fetchCurrent();
                        transactions.fetchGroupByDay(1, 50);
                        setOpenLoadingModal(false);
                        setTransaction(transaction_1);
                        setOpenSuccessfulModal(true);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _e.sent();
                        setOpenLoadingModal(false);
                        if (((_a = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _a === void 0 ? void 0 : _a.data) === 'INVALID_PHONE')
                            return [2 /*return*/, setOpenInvalidPhoneModal(true)];
                        if (((_b = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _b === void 0 ? void 0 : _b.data) === 'INSUFFICIENT_FUNDS')
                            return [2 /*return*/, setOpenInsufficientFundsModal(true)];
                        if (((_c = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _c === void 0 ? void 0 : _c.data) === 'WRONG_COMPANY')
                            return [2 /*return*/, setOpenWrongCompanyModal(true)];
                        if (((_d = err_1 === null || err_1 === void 0 ? void 0 : err_1.response) === null || _d === void 0 ? void 0 : _d.data) === 'DUPLICATED')
                            return [2 /*return*/, setOpenDuplicatedModal(true)];
                        setOpenUnknownErrorModal(true);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(ProductCard, null,
        React.createElement(ProductCardTitle, { title: 'Recarga' }),
        React.createElement(ProductCardContent, null,
            React.createElement(ProductCardInfo, { product: product }),
            React.createElement(ProductCardFields, null,
                React.createElement(TextField, { formField: form.fields.phone, type: 'tel', label: 'Tel\u00E9fono', validators: [
                        validators.required,
                        validators.phone
                    ] }),
                React.createElement(TextField, { style: style.amountTextField, formField: form.fields.amount, type: 'number', label: 'Monto', onEnter: handleConfirm, hint: "\n              min. ".concat(formatCurrency(product.min), " - \n              m\u00E1x. ").concat(formatCurrency(product.max), "\n            "), validators: [
                        validators.required,
                        validators.min(product.min),
                        validators.max(product.max)
                    ] }))),
        React.createElement(ProductCardButtons, { onAccept: handleConfirm, onCancel: function () { return form.clear(); } }),
        React.createElement(TopupConfirmModal, { open: openConfirmModal, company: product.name, phone: form.value.phone, amount: parseInt(form.value.amount), onAccept: handleSend, onClose: function () { return setOpenConfirmModal(false); } }),
        React.createElement(LoadingModal, { title: 'Realizando operaci\u00F3n', open: openLoadingModal }),
        React.createElement(TopupModal, { open: openSuccessfulModal, title: 'Recarga exitosa', transaction: transaction, onPrint: function () { return printTopup(business, transaction); }, onClose: function () { return setOpenSuccessfulModal(false); } }),
        React.createElement(ErrorModal, { open: openInvalidPhoneModal, title: 'N\u00FAmero de tel\u00E9fono invalido', description: 'Revice el n\u00FAmero de tel\u00E9fono e intentelo denuevo.', onClose: function () { return setOpenInvalidPhoneModal(false); } }),
        React.createElement(ErrorModal, { open: openInsufficientFundsModal, title: 'No posee suficiente balance', description: 'No posee suficiente balance para realizar esta operaci\u00F3n.', onClose: function () { return setOpenInsufficientFundsModal(false); } }),
        React.createElement(ErrorModal, { open: openWrongCompanyModal, title: 'Compa\u00F1\u00EDa incorrecta', description: 'Este n\u00FAmero de tel\u00E9fono no pertenece a esta compa\u00F1\u00EDa, intente con otra compa\u00F1\u00EDa.', onClose: function () { return setOpenWrongCompanyModal(false); } }),
        React.createElement(ErrorModal, { open: openDuplicatedModal, title: 'Recarga repetida', description: 'No se pueden realizar varias recarga a la vez a un mismo n\u00FAmero. Vuelva a intentarlo despues de 5 minutos.', onClose: function () { return setOpenDuplicatedModal(false); } }),
        React.createElement(ErrorModal, { open: openUnknownErrorModal, title: 'Ocurrio un error', description: 'Intentelo denuevo m\u00E1s tarde.', onClose: function () { return setOpenUnknownErrorModal(false); } })));
}
