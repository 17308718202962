var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, memo, useState } from 'react';
import { TransactionApi } from '@recargas-dominicanas/core/api';
import { LoadingModal, TransactionItem } from '@recargas-dominicanas/core/components';
import { useBusiness, useSalesReport, useTransactions } from '@recargas-dominicanas/core/store';
import { InvoiceCancelModal } from '../invoice-cancel-modal/invoice-cancel-modal.component';
import { InvoiceModal } from '../invoice-modal/invoice-modal.component';
import { ErrorModal } from '../error-modal/error-modal.component';
import { printInvoice } from '../../utils/printInvoice';
import { style } from './invoice-item.module.css';
export var InvoiceItem = memo(function InvoiceItem(props) {
    var transaction = props.transaction;
    var businessStore = useBusiness();
    var salesReport = useSalesReport();
    var transactions = useTransactions();
    var business = businessStore.get();
    var _a = useState(false), openInvoiceModal = _a[0], setOpenInvoiceModal = _a[1];
    var _b = useState(false), openCancelModal = _b[0], setOpenCancelModal = _b[1];
    var _c = useState(false), openLoadingModal = _c[0], setOpenLoadingModal = _c[1];
    var _d = useState(false), openErrorModal = _d[0], setOpenErrorModal = _d[1];
    var _e = useState(false), openSuccessfulModal = _e[0], setOpenSuccessfulModal = _e[1];
    function handleOpenCancelModal() {
        setOpenInvoiceModal(false);
        setOpenCancelModal(true);
    }
    function handleCancel() {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setOpenCancelModal(false);
                        setOpenLoadingModal(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, TransactionApi.cancelInvoice(transaction.id)];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, Promise.allSettled([
                                salesReport.fetchCurrent(),
                                transactions.fetchGroupByDay(1, 50)
                            ])];
                    case 3:
                        _b.sent();
                        setOpenLoadingModal(false);
                        setOpenSuccessfulModal(true);
                        return [3 /*break*/, 5];
                    case 4:
                        _a = _b.sent();
                        setOpenLoadingModal(false);
                        setOpenErrorModal(true);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(Fragment, null,
        React.createElement(TransactionItem, { style: style.item, key: transaction.id, transaction: transaction, showMenu: false, onClick: function () { return setOpenInvoiceModal(true); }, onCancel: function () { return setOpenCancelModal(true); } }),
        React.createElement(InvoiceModal, { open: openInvoiceModal, title: 'Factura', transaction: transaction, onPrint: function () { return printInvoice(business, transaction); }, onCancel: handleOpenCancelModal, onClose: function () { return setOpenInvoiceModal(false); } }),
        React.createElement(InvoiceCancelModal, { open: openCancelModal, transaction: transaction, overlayEnterAnimation: false, onAccept: handleCancel, onClose: function () { return setOpenCancelModal(false); } }),
        React.createElement(LoadingModal, { open: openLoadingModal, title: 'Cancelando pago', overlayEnterAnimation: false }),
        React.createElement(InvoiceModal, { open: openSuccessfulModal, title: 'Pago cancelado', transaction: transaction, overlayEnterAnimation: false, onPrint: function () { return printInvoice(business, transaction); }, onClose: function () { return setOpenSuccessfulModal(false); } }),
        React.createElement(ErrorModal, { open: openErrorModal, overlayEnterAnimation: false, title: 'Error cancelando pago', description: 'Ocurrio un error al cancelar el pago.', onClose: function () { return setOpenErrorModal(false); } })));
}, propsAreEqual);
function propsAreEqual(prev, next) {
    return (prev.transaction.id === next.transaction.id &&
        prev.transaction.cancelled === next.transaction.cancelled);
}
