import React, { Fragment, useRef, useState } from 'react';
import { formatTime, formatCurrency, expired } from '@recargas-dominicanas/core/utils';
import { TableRow, CellImage, Cell, CellIcon, Menu, Option } from '@recargas-dominicanas/core/components';
export function TransactionRow(props) {
    var _a;
    var transaction = props.transaction, onClick = props.onClick, onPrint = props.onPrint, onCancel = props.onCancel;
    var _b = useState(false), openMenu = _b[0], setOpenMenu = _b[1];
    var anchor = useRef(null);
    function showCancelOption() {
        return ((transaction.product.type === 'Recarga' || transaction.product.type === 'Factura') &&
            !transaction.cancelled &&
            !expired(transaction));
    }
    return (React.createElement(Fragment, null,
        React.createElement(TableRow, { key: transaction.id, onClick: onClick },
            React.createElement(CellImage, { src: transaction.product.image }),
            (transaction.product.type === 'Recarga' || transaction.product.type === 'Paquetico') &&
                React.createElement(Cell, { text: transaction.phone }),
            transaction.product.type === 'Pin' &&
                React.createElement(Cell, { text: '* * * * * * * *' }),
            transaction.product.type === 'Factura' &&
                React.createElement(Cell, { text: (_a = transaction.contract) === null || _a === void 0 ? void 0 : _a.nic }),
            React.createElement(Cell, { text: transaction.product.type }),
            React.createElement(Cell, { text: formatTime(transaction.date) }),
            React.createElement(Cell, { weight: 'medium', color: 'green', text: formatCurrency(transaction.amount) }),
            transaction.cancelled
                ? React.createElement(Cell, { text: 'Cancelada', weight: 'medium', color: 'red' })
                : React.createElement(Cell, { text: 'Realizada', weight: 'medium', color: 'gray' }),
            React.createElement(CellIcon, { icon: 'more_horiz', refIcon: anchor, onClick: function () { return setOpenMenu(function (state) { return !state; }); } })),
        React.createElement(Menu, { open: openMenu, anchor: anchor, top: 4, onClose: function () { return setOpenMenu(false); } },
            React.createElement(Option, { text: 'Reimprimir', hiddeIcon: true, onClick: onPrint }),
            showCancelOption() &&
                React.createElement(Option, { text: 'Cancelar', hiddeIcon: true, onClick: onCancel }))));
}
