// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-modal-item-detailItem_container-koSgI {
  margin: 0px 4px;
}

.detail-modal-item-detailItem_title-uje2J {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.9);
}

.detail-modal-item-detailItem_text-uT9tX {
  color: rgba(0, 0, 0, 0.75);
}

.detail-modal-item-detailItem_green-i3k6S {
  color: var(--money-color)
}
`, "",{"version":3,"sources":["webpack://./src/components/detail-modal-item/detail-modal-item.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;AACF","sourcesContent":[".detailItem_container {\n  margin: 0px 4px;\n}\n\n.detailItem_title {\n  font-weight: normal;\n  color: rgba(0, 0, 0, 0.9);\n}\n\n.detailItem_text {\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.detailItem_green {\n  color: var(--money-color)\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailItem_container": `detail-modal-item-detailItem_container-koSgI`,
	"detailItem_title": `detail-modal-item-detailItem_title-uje2J`,
	"detailItem_text": `detail-modal-item-detailItem_text-uT9tX`,
	"detailItem_green": `detail-modal-item-detailItem_green-i3k6S`
};
export default ___CSS_LOADER_EXPORT___;
