import React from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { DetailModal } from '../detail-modal/detail-modal.component';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { ModalContent, OutlineButton, ModalOptions } from '@recargas-dominicanas/core/components';
export function SalesReportSuccessfulModal(props) {
    var open = props.open, salesReport = props.salesReport, onPrint = props.onPrint, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, title: 'Cierre de ventas exitoso', overlayEnterAnimation: false, onClose: onClose },
        React.createElement(ModalContent, null,
            React.createElement(DetailModalItem, { title: 'Balance', color: 'green', text: formatCurrency(salesReport.balance) }),
            React.createElement(DetailModalItem, { title: 'Ventas', color: 'green', text: formatCurrency(salesReport.sales) }),
            React.createElement(DetailModalItem, { title: 'Balance consumido', color: 'green', text: formatCurrency(salesReport.sales - salesReport.profit) }),
            React.createElement(DetailModalItem, { title: 'Beneficio', color: 'green', text: formatCurrency(salesReport.profit) }),
            React.createElement(DetailModalItem, { title: 'Fecha', text: formatDate(salesReport.date) })),
        React.createElement(ModalOptions, null,
            React.createElement(OutlineButton, { icon: 'receipt', text: 'Imprimir', onClick: onPrint }))));
}
