import React, { Fragment } from 'react';
import { formatCurrency, formatDate, formatPhone } from '@recargas-dominicanas/core/utils';
import { DetailModal } from '../detail-modal/detail-modal.component';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { ModalContent, OutlineButton, ModalOptions, } from '@recargas-dominicanas/core/components';
export function DataPlanModal(props) {
    var open = props.open, title = props.title, dataPlan = props.dataPlan, transaction = props.transaction, onPrint = props.onPrint, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, overlayEnterAnimation: false, title: title, onClose: onClose },
        React.createElement(ModalContent, null, transaction &&
            React.createElement(Fragment, null,
                React.createElement(DetailModalItem, { title: 'Compa\u00F1\u00EDa', text: transaction.product.name }),
                React.createElement(DetailModalItem, { title: 'Tel\u00E9fono', text: formatPhone(transaction.phone) }),
                dataPlan &&
                    React.createElement(DetailModalItem, { title: 'Plan de datos', text: dataPlan.name }),
                React.createElement(DetailModalItem, { title: 'Precio', text: formatCurrency(transaction.amount), color: 'green' }),
                React.createElement(DetailModalItem, { title: 'Beneficio', text: formatCurrency(transaction.profit), color: 'green' }),
                React.createElement(DetailModalItem, { title: 'Fecha', text: formatDate(transaction.date) }))),
        React.createElement(ModalOptions, null,
            React.createElement(OutlineButton, { icon: 'receipt', text: 'Imprimir', onClick: onPrint }))));
}
