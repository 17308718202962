// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile-container-IMgqp {
  width: 324px;
  position: absolute;
  top: 60px;
  right: 24px;
  background-color: white;
  border-radius: 4px;
  outline: none;
  box-shadow: 0px 2px 12px lightgray;
  padding: 20px 20px 24px;
  z-index: 3;
}

.user-profile-animation_enter-k4vPu {
  opacity: 0.5;
  transform: translateY(-16px);
}

.user-profile-animation_enterActive-KfsH9 {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 180ms ease-out, transform 180ms ease-out;
}

.user-profile-animation_exit-DlGtT {
  opacity: 1;
  transform: translateY(0px);
}

.user-profile-animation_exitActive-nDUT7 {
  opacity: 0.1;
  transform: translateY(-16px);
  transition: opacity 180ms linear, transform 180ms linear;
}

.user-profile-name-R4Aa5 {
  font-size: 16px;
  margin-top: 12px;
}

.user-profile-account-RFGrc {
  color: var(--accent-color);
  font-weight: 500;
  margin-top: 12px;
}

.user-profile-idItem_container-tFWCC {
  margin-top: 24px;
}

.user-profile-logoutButton_base-CvaJ3 {
  width: 100%;
  justify-content: start;
  margin-top: 20px;
  padding: 0px 12px;
}

.user-profile-logoutButton_icon-DNRh0 {
  --size: 22px;
  margin-right: 12px;
}

.user-profile-logoutButton_text-j08eV {
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/user-profile/user-profile.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,kCAAkC;EAClC,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,0BAA0B;EAC1B,4DAA4D;AAC9D;;AAEA;EACE,UAAU;EACV,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,wDAAwD;AAC1D;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".container {\n  width: 324px;\n  position: absolute;\n  top: 60px;\n  right: 24px;\n  background-color: white;\n  border-radius: 4px;\n  outline: none;\n  box-shadow: 0px 2px 12px lightgray;\n  padding: 20px 20px 24px;\n  z-index: 3;\n}\n\n.animation_enter {\n  opacity: 0.5;\n  transform: translateY(-16px);\n}\n\n.animation_enterActive {\n  opacity: 1;\n  transform: translateY(0px);\n  transition: opacity 180ms ease-out, transform 180ms ease-out;\n}\n\n.animation_exit {\n  opacity: 1;\n  transform: translateY(0px);\n}\n\n.animation_exitActive {\n  opacity: 0.1;\n  transform: translateY(-16px);\n  transition: opacity 180ms linear, transform 180ms linear;\n}\n\n.name {\n  font-size: 16px;\n  margin-top: 12px;\n}\n\n.account {\n  color: var(--accent-color);\n  font-weight: 500;\n  margin-top: 12px;\n}\n\n.idItem_container {\n  margin-top: 24px;\n}\n\n.logoutButton_base {\n  width: 100%;\n  justify-content: start;\n  margin-top: 20px;\n  padding: 0px 12px;\n}\n\n.logoutButton_icon {\n  --size: 22px;\n  margin-right: 12px;\n}\n\n.logoutButton_text {\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `user-profile-container-IMgqp`,
	"animation_enter": `user-profile-animation_enter-k4vPu`,
	"animation_enterActive": `user-profile-animation_enterActive-KfsH9`,
	"animation_exit": `user-profile-animation_exit-DlGtT`,
	"animation_exitActive": `user-profile-animation_exitActive-nDUT7`,
	"name": `user-profile-name-R4Aa5`,
	"account": `user-profile-account-RFGrc`,
	"idItem_container": `user-profile-idItem_container-tFWCC`,
	"logoutButton_base": `user-profile-logoutButton_base-CvaJ3`,
	"logoutButton_icon": `user-profile-logoutButton_icon-DNRh0`,
	"logoutButton_text": `user-profile-logoutButton_text-j08eV`
};
export default ___CSS_LOADER_EXPORT___;
