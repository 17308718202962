// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pin-select_container-b0gUU {
  width: 260px;
}

.pin-select_input-LPmN2 {
  padding: 0px 8px 0px 12px;
}

.pin-select_value-vkryw {
  color: var(--money-color);
  font-weight: 500;
}

.pin-select_popup-j0Kyh {
  max-height: 240px;
  overflow: auto;
}

.pin-option_container-lqBEF {
  font-size: 14px;
  font-weight: 500;
  color: var(--money-color);
  padding: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pin/pin.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".select_container {\n  width: 260px;\n}\n\n.select_input {\n  padding: 0px 8px 0px 12px;\n}\n\n.select_value {\n  color: var(--money-color);\n  font-weight: 500;\n}\n\n.select_popup {\n  max-height: 240px;\n  overflow: auto;\n}\n\n.option_container {\n  font-size: 14px;\n  font-weight: 500;\n  color: var(--money-color);\n  padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_container": `pin-select_container-b0gUU`,
	"select_input": `pin-select_input-LPmN2`,
	"select_value": `pin-select_value-vkryw`,
	"select_popup": `pin-select_popup-j0Kyh`,
	"option_container": `pin-option_container-lqBEF`
};
export default ___CSS_LOADER_EXPORT___;
