// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-content-NjTtB {
  max-width: 1000px;
  margin: auto;
  padding: 40px 24px 24px;
}

.settings-titleContainer-rX3lh {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.settings-icon-KFWWx {
  --size: 26px;
  color: rgba(0, 0, 0, 0.9);
}

.settings-title-RDVm6 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: 500;
}

.settings-item-LoTrX {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: var(--small-border-radius);
  padding: 12px 16px;
  margin-top: 12px;
}

.settings-itemTitle-E7Vtq {
  font-size: 15px;
}

.settings-textField_container-cNJNL {
  width: 80px;
}

.settings-textField_input-L0myp {
  height: 32px;
  padding: 0px 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,qCAAqC;EACrC,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".content {\n  max-width: 1000px;\n  margin: auto;\n  padding: 40px 24px 24px;\n}\n\n.titleContainer {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  margin-bottom: 20px;\n}\n\n.icon {\n  --size: 26px;\n  color: rgba(0, 0, 0, 0.9);\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border: 1px solid rgba(0, 0, 0, 0.12);\n  border-radius: var(--small-border-radius);\n  padding: 12px 16px;\n  margin-top: 12px;\n}\n\n.itemTitle {\n  font-size: 15px;\n}\n\n.textField_container {\n  width: 80px;\n}\n\n.textField_input {\n  height: 32px;\n  padding: 0px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `settings-content-NjTtB`,
	"titleContainer": `settings-titleContainer-rX3lh`,
	"icon": `settings-icon-KFWWx`,
	"title": `settings-title-RDVm6`,
	"item": `settings-item-LoTrX`,
	"itemTitle": `settings-itemTitle-E7Vtq`,
	"textField_container": `settings-textField_container-cNJNL`,
	"textField_input": `settings-textField_input-L0myp`
};
export default ___CSS_LOADER_EXPORT___;
