import React from 'react';
import { formatCurrency, formatDate } from '@recargas-dominicanas/core/utils';
import { ModalContent, ModalActions, OutlineButton } from '@recargas-dominicanas/core/components';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { DetailModal } from '../detail-modal/detail-modal.component';
export function InvoiceCancelModal(props) {
    var open = props.open, transaction = props.transaction, overlayEnterAnimation = props.overlayEnterAnimation, onAccept = props.onAccept, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, title: 'Cancelar pago', overlayEnterAnimation: overlayEnterAnimation, onClose: onClose },
        React.createElement(ModalContent, null,
            React.createElement(DetailModalItem, { title: 'Compa\u00F1\u00EDa', text: transaction.product.name }),
            React.createElement(DetailModalItem, { title: 'No. contrato', text: transaction.contract.nic }),
            React.createElement(DetailModalItem, { title: 'Titular', text: transaction.contract.name }),
            React.createElement(DetailModalItem, { title: 'Monto', text: formatCurrency(transaction.amount), color: 'green' }),
            React.createElement(DetailModalItem, { title: 'Fecha', text: formatDate(transaction.date) })),
        React.createElement(ModalActions, null,
            React.createElement(OutlineButton, { text: 'Confirmar', onClick: onAccept }))));
}
