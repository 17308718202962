import React from 'react';
import { style } from './confirm-modal.module.css';
import { Modal, ModalToolbar, ModalContent, ModalActions, OutlineButton } from '@recargas-dominicanas/core/components';
export function ConfirmModal(props) {
    var open = props.open, title = props.title, description = props.description, _a = props.overlayEnterAnimation, overlayEnterAnimation = _a === void 0 ? false : _a, onClose = props.onClose;
    return (React.createElement(Modal, { open: open, style: style.modal, overlayEnterAnimation: overlayEnterAnimation },
        React.createElement(ModalToolbar, { style: style.modalToolbar, title: title }),
        React.createElement(ModalContent, { className: style.modalContent }, description),
        React.createElement(ModalActions, { style: style.actionButton },
            React.createElement(OutlineButton, { text: 'Aceptar', onClick: onClose }))));
}
