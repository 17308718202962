// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container-SKTxw {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-toolbar-oCljj {
  height: 52px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  margin: 0px 32px;
}

.home-title_container-MmsFT {
  flex: 1;
}

.home-title_title-VTz62 {
  font-size: 17px;
}

.home-options-BSiAt {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 4px;
}

.home-content-aPL_H {
  display: flex;
  flex-grow: 1;
  gap: 32px;
  justify-content: space-between;
  margin: 0px 32px 20px;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,OAAO;EACP,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,SAAS;EACT,8BAA8B;EAC9B,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.toolbar {\n  height: 52px;\n  display: flex;\n  flex-shrink: 0;\n  justify-content: space-between;\n  align-items: center;\n  margin: 0px 32px;\n}\n\n.title_container {\n  flex: 1;\n}\n\n.title_title {\n  font-size: 17px;\n}\n\n.options {\n  display: flex;\n  flex: 1;\n  justify-content: flex-end;\n  padding-right: 4px;\n}\n\n.content {\n  display: flex;\n  flex-grow: 1;\n  gap: 32px;\n  justify-content: space-between;\n  margin: 0px 32px 20px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `home-container-SKTxw`,
	"toolbar": `home-toolbar-oCljj`,
	"title_container": `home-title_container-MmsFT`,
	"title_title": `home-title_title-VTz62`,
	"options": `home-options-BSiAt`,
	"content": `home-content-aPL_H`
};
export default ___CSS_LOADER_EXPORT___;
