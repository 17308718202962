var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { TransactionApi } from '@recargas-dominicanas/core/api';
import { useForm, validators } from '@recargas-dominicanas/core/utils';
import { TextField, LoadingModal } from '@recargas-dominicanas/core/components';
import { useBusiness, useSalesReport, useTransactions } from '@recargas-dominicanas/core/store';
import { InvoiceConfirmModal } from '../invoice-confirm-modal/invoice-confirm-modal.component';
import { InvoiceModal } from '../invoice-modal/invoice-modal.component';
import { ErrorModal } from '../error-modal/error-modal.component';
import { printInvoice } from '../../utils/printInvoice';
import { ProductCard, ProductCardButtons, ProductCardContent, ProductCardFields, ProductCardInfo, ProductCardTitle } from '../product-card';
export function Invoice(props) {
    var product = props.product;
    var businessStore = useBusiness();
    var salesReports = useSalesReport();
    var transactions = useTransactions();
    var salesReport = salesReports.getCurrent();
    var business = businessStore.get();
    var form = useForm({ nic: '' });
    var _a = useState(), invoice = _a[0], setInvoice = _a[1];
    var _b = useState(), transaction = _b[0], setTransaction = _b[1];
    var _c = useState(false), openInvoiceModal = _c[0], setOpenInvoiceModal = _c[1];
    var _d = useState(false), openGettingInvoiceModal = _d[0], setOpenGettingInvoiceModal = _d[1];
    var _e = useState(false), openSendingInvoiceModal = _e[0], setOpenSendingInvoiceModal = _e[1];
    var _f = useState(false), openNoOutstandingInvoices = _f[0], setOpenNoOutstandingInvoices = _f[1];
    var _g = useState(false), openErrorModal = _g[0], setOpenErrorModal = _g[1];
    var _h = useState(false), openPayErrorModal = _h[0], setOpenPayErrorModal = _h[1];
    var _j = useState(false), openInsufficientFundsModal = _j[0], setOpenInsufficientFundsModal = _j[1];
    var _k = useState(false), openSuccessfulModal = _k[0], setOpenSuccessfulModal = _k[1];
    useEffect(function () {
        form.clear();
    }, [product.id]);
    function handleGetInvoice() {
        return __awaiter(this, void 0, void 0, function () {
            var invoice_1, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        document.activeElement.blur();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!form.isValid())
                            return [2 /*return*/];
                        setOpenGettingInvoiceModal(true);
                        return [4 /*yield*/, TransactionApi.getInvoice(product.id, form.value.nic)];
                    case 2:
                        invoice_1 = _a.sent();
                        setOpenGettingInvoiceModal(false);
                        setInvoice(invoice_1);
                        setOpenInvoiceModal(true);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        setOpenGettingInvoiceModal(false);
                        if (err_1.response.data === 'NO_OUTSTANDING_INVOICES') {
                            setOpenNoOutstandingInvoices(true);
                        }
                        else {
                            setOpenErrorModal(true);
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function handlePayInvoice() {
        return __awaiter(this, void 0, void 0, function () {
            var transaction_1, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        setOpenInvoiceModal(false);
                        if (salesReport.balance < invoice.amount) {
                            setOpenInsufficientFundsModal(true);
                            return [2 /*return*/];
                        }
                        setOpenSendingInvoiceModal(true);
                        return [4 /*yield*/, TransactionApi.payInvoice(product.id, invoice.nic, invoice.amount)];
                    case 1:
                        transaction_1 = _a.sent();
                        salesReports.fetchCurrent(),
                            transactions.fetchGroupByDay(1, 50);
                        form.clear();
                        setOpenSendingInvoiceModal(false);
                        setOpenSuccessfulModal(true);
                        setTransaction(transaction_1);
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        setOpenSendingInvoiceModal(false);
                        setOpenPayErrorModal(true);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(ProductCard, null,
        React.createElement(ProductCardTitle, { title: 'Servicio' }),
        React.createElement(ProductCardContent, null,
            React.createElement(ProductCardInfo, { product: product }),
            React.createElement(ProductCardFields, null,
                React.createElement(TextField, { formField: form.fields.nic, label: 'No. Contrato', validators: [validators.required], onEnter: handleGetInvoice }))),
        React.createElement(ProductCardButtons, { onAccept: handleGetInvoice, onCancel: function () { return form.clear(); } }),
        React.createElement(InvoiceConfirmModal, { open: openInvoiceModal, company: product.name, invoice: invoice, onClose: function () { return setOpenInvoiceModal(false); }, onAccept: handlePayInvoice }),
        React.createElement(LoadingModal, { open: openGettingInvoiceModal, title: 'Obteniendo datos de facturaci\u00F3n' }),
        React.createElement(LoadingModal, { open: openSendingInvoiceModal, title: 'Realizando pago' }),
        React.createElement(InvoiceModal, { open: openSuccessfulModal, title: 'Pago realizado', transaction: transaction, onPrint: function () { return printInvoice(business, transaction); }, onClose: function () { return setOpenSuccessfulModal(false); } }),
        React.createElement(ErrorModal, { open: openNoOutstandingInvoices, title: 'No tiene facturas pendientes', description: 'No se encontraron facturas pendientes de pago.', onClose: function () { return setOpenNoOutstandingInvoices(false); } }),
        React.createElement(ErrorModal, { open: openErrorModal, title: 'Ocurri\u00F3 un error', description: 'No se pudieron obtener los datos de facturaci\u00F3n. Revise el n\u00FAmero de contrato e intente de nuevo m\u00E1s tarde.', onClose: function () { return setOpenErrorModal(false); } }),
        React.createElement(ErrorModal, { open: openInsufficientFundsModal, title: 'Balance insuficiente', description: 'No posee balance suficiente para realizar esta operaci\u00F3n.', onClose: function () { return setOpenInsufficientFundsModal(false); } }),
        React.createElement(ErrorModal, { open: openPayErrorModal, title: 'No se pudo realizar el pago', description: 'Ocurri\u00F3 un error al realizar el pago. Int\u00E9ntelo de nuevo m\u00E1s tarde.', onClose: function () { return setOpenPayErrorModal(false); } })));
}
