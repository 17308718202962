// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-card-info-container-nfxnm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
}

.product-card-info-image-duEGP {
  width: 64px;
  height: 64px;
}

.product-card-info-title_container-iSpVz {
  margin-top: 16px;
}

.product-card-info-title_title-_1_Dq {
  font-size: 16px;
  color: rgb(0 0 0 / 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/components/product-card/product-card-info/product-card-info.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 36px;\n}\n\n.image {\n  width: 64px;\n  height: 64px;\n}\n\n.title_container {\n  margin-top: 16px;\n}\n\n.title_title {\n  font-size: 16px;\n  color: rgb(0 0 0 / 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `product-card-info-container-nfxnm`,
	"image": `product-card-info-image-duEGP`,
	"title_container": `product-card-info-title_container-iSpVz`,
	"title_title": `product-card-info-title_title-_1_Dq`
};
export default ___CSS_LOADER_EXPORT___;
