import React, { useEffect, useState } from 'react';
import { Icon, TextField } from '@recargas-dominicanas/core/components';
import { style } from './settings.module.css';
export function Settings() {
    var _a = useState(), ticketWith = _a[0], setTicketWith = _a[1];
    var _b = useState(), endLines = _b[0], setEndLines = _b[1];
    useEffect(function () {
        var width = localStorage.getItem('ticketWidth');
        var endLines = localStorage.getItem('endLines');
        setTicketWith(width || '40');
        setEndLines(endLines || '5');
    }, []);
    function handleTicketWidthBlur() {
        if (ticketWith === '' || parseInt(ticketWith) < 20) {
            setTicketWith('20');
            localStorage.setItem('ticketWidth', '20');
            return;
        }
        localStorage.setItem('ticketWidth', ticketWith);
    }
    function handleEndLinesBlur() {
        if (endLines === '' || parseInt(endLines) < 1) {
            setEndLines('1');
            localStorage.setItem('endLines', '1');
            return;
        }
        localStorage.setItem('endLines', endLines);
    }
    if (ticketWith === undefined || endLines === undefined) {
        return null;
    }
    return (React.createElement("div", { className: style.container },
        React.createElement("div", { className: style.content },
            React.createElement("div", { className: style.titleContainer },
                React.createElement(Icon, { className: style.icon, icon: 'print' }),
                React.createElement("div", { className: style.title }, "Impresora")),
            React.createElement("div", { className: style.item },
                React.createElement("div", { className: style.itemTitle }, "Ancho de la impresi\u00F3n"),
                React.createElement(TextField, { style: style.textField, type: 'number', value: ticketWith, onChange: setTicketWith, onBlur: handleTicketWidthBlur })),
            React.createElement("div", { className: style.item },
                React.createElement("div", { className: style.itemTitle }, "Nuevas lineas al final de la impresi\u00F3n"),
                React.createElement(TextField, { style: style.textField, type: 'number', value: endLines, onChange: setEndLines, onBlur: handleEndLinesBlur })))));
}
