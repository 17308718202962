import React from 'react';
import { formatCurrency } from '@recargas-dominicanas/core/utils';
import { DetailModal } from '../detail-modal/detail-modal.component';
import { DetailModalItem } from '../detail-modal-item/detail-modal-item.component';
import { ModalContent, ModalActions, Button, OutlineButton } from '@recargas-dominicanas/core/components';
export function PinConfirmModal(props) {
    var open = props.open, company = props.company, price = props.price, onAccept = props.onAccept, onClose = props.onClose;
    return (React.createElement(DetailModal, { open: open, title: 'Confirmar pin' },
        React.createElement(ModalContent, null,
            React.createElement(DetailModalItem, { title: 'Compa\u00F1\u00EDa', text: company }),
            React.createElement(DetailModalItem, { title: 'Precio', text: formatCurrency(price), color: 'green' })),
        React.createElement(ModalActions, null,
            React.createElement(Button, { text: 'Cancelar', onClick: onClose }),
            React.createElement(OutlineButton, { text: 'Aceptar', onClick: onAccept }))));
}
