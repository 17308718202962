// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-plan-select_container-eou0W {
  width: 260px;
}

.data-plan-select_input-WoxjL {
  padding: 0px 8px 0px 12px;
}

.data-plan-select_popup-TBCE5 {
  max-height: 200px;
  overflow: auto;
}

.data-plan-circularProgress-r1Vwg {
  display: block;
  margin: 12px auto;
  color: var(--accent-color);
}

.data-plan-circularProgress-r1Vwg .rmwc-circular-progress__path {
  stroke-width: 3px;
}

.data-plan-dataPlanItem-Zk39Q {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;
}

.data-plan-price-fVmcD {
  color: var(--money-color);
  font-size: 14px;
  font-weight: 500;
}

.data-plan-dataPlansError-mStI0 {
  width: 260px;
  margin: 24px auto;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: var(--large-border-radius);
  display: flex;
}

.data-plan-dataPlansErrorIcon-8JKEp {
  flex-shrink: 0;
  margin-right: 12px;
  color: var(--accent-color);
}

.data-plan-dataPlansErrorTitle-dnqAH {
  color: var(--accent-color);
  font-size: 14px;
}

.data-plan-dataPlansErrorDescription-WJ6G7 {
  font-size: 14px;
  font-weight: 300;
  margin-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/data-plan/data-plan.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,qCAAqC;EACrC,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".select_container {\n  width: 260px;\n}\n\n.select_input {\n  padding: 0px 8px 0px 12px;\n}\n\n.select_popup {\n  max-height: 200px;\n  overflow: auto;\n}\n\n.circularProgress {\n  display: block;\n  margin: 12px auto;\n  color: var(--accent-color);\n}\n\n.circularProgress :global(.rmwc-circular-progress__path) {\n  stroke-width: 3px;\n}\n\n.dataPlanItem {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: 12px 8px;\n}\n\n.price {\n  color: var(--money-color);\n  font-size: 14px;\n  font-weight: 500;\n}\n\n.dataPlansError {\n  width: 260px;\n  margin: 24px auto;\n  padding: 12px;\n  border: 1px solid var(--border-color);\n  border-radius: var(--large-border-radius);\n  display: flex;\n}\n\n.dataPlansErrorIcon {\n  flex-shrink: 0;\n  margin-right: 12px;\n  color: var(--accent-color);\n}\n\n.dataPlansErrorTitle {\n  color: var(--accent-color);\n  font-size: 14px;\n}\n\n.dataPlansErrorDescription {\n  font-size: 14px;\n  font-weight: 300;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select_container": `data-plan-select_container-eou0W`,
	"select_input": `data-plan-select_input-WoxjL`,
	"select_popup": `data-plan-select_popup-TBCE5`,
	"circularProgress": `data-plan-circularProgress-r1Vwg`,
	"dataPlanItem": `data-plan-dataPlanItem-Zk39Q`,
	"price": `data-plan-price-fVmcD`,
	"dataPlansError": `data-plan-dataPlansError-mStI0`,
	"dataPlansErrorIcon": `data-plan-dataPlansErrorIcon-8JKEp`,
	"dataPlansErrorTitle": `data-plan-dataPlansErrorTitle-dnqAH`,
	"dataPlansErrorDescription": `data-plan-dataPlansErrorDescription-WJ6G7`
};
export default ___CSS_LOADER_EXPORT___;
