// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-container-S6T0M {
  max-width: 800px;
  flex: 1;
  overflow: auto;
}

.products-container-S6T0M::-webkit-scrollbar {
  display: none;
}

.products-content-heDKC {
  border: 1px solid #ECECEC;
  border-radius: 8px;
}

.products-content-heDKC:not(:first-of-type) {
  margin-top: 32px;
}

.products-products-hpODX {
  display: grid;
  grid-template-columns: repeat(auto-fit, 132px);
  justify-items: center;
  margin-top: 20px;
}

.products-title_container-xTe_L {
  margin: 16px 20px;
}

.products-title_title-a2ZxC {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
}

.products-productItem_container-jgq40 {
  width: fit-content;
  margin: 0px 0px 20px;
}

.products-productItem_name-QWyhW {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.products-productItem_image-lpngq {
  width: 48px;
  height: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/components/products/products.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,OAAO;EACP,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8CAA8C;EAC9C,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  max-width: 800px;\n  flex: 1;\n  overflow: auto;\n}\n\n.container::-webkit-scrollbar {\n  display: none;\n}\n\n.content {\n  border: 1px solid #ECECEC;\n  border-radius: 8px;\n}\n\n.content:not(:first-of-type) {\n  margin-top: 32px;\n}\n\n.products {\n  display: grid;\n  grid-template-columns: repeat(auto-fit, 132px);\n  justify-items: center;\n  margin-top: 20px;\n}\n\n.title_container {\n  margin: 16px 20px;\n}\n\n.title_title {\n  font-size: 17px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.productItem_container {\n  width: fit-content;\n  margin: 0px 0px 20px;\n}\n\n.productItem_name {\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.productItem_image {\n  width: 48px;\n  height: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `products-container-S6T0M`,
	"content": `products-content-heDKC`,
	"products": `products-products-hpODX`,
	"title_container": `products-title_container-xTe_L`,
	"title_title": `products-title_title-a2ZxC`,
	"productItem_container": `products-productItem_container-jgq40`,
	"productItem_name": `products-productItem_name-QWyhW`,
	"productItem_image": `products-productItem_image-lpngq`
};
export default ___CSS_LOADER_EXPORT___;
