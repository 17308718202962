import React, { Fragment, useEffect, useRef } from 'react';
import emptyIcon from '../../images/history.svg';
import { useTransactions } from '@recargas-dominicanas/core/store';
import { PinRow } from '../../components/pin-row/pin-row.component';
import { TopupRow } from '../../components/topup-row/topup-row.component';
import { InvoiceRow } from '../../components/invoice-row/invoice-row.component';
import { DataPlanRow } from '../../components/data-plan-row/data-plan-row.component';
import { Empty } from '../../components/empty/empty.components';
import { Error } from '../../components/error/error.component';
import { style } from './transactions.module.css';
import { OutlineCard, PageContainer, PageContent, PageToolbar, Pagination, Table, TableHeader, Title } from '@recargas-dominicanas/core/components';
export function Transactions() {
    var store = useTransactions();
    var page = store.getPage();
    var tableRef = useRef(null);
    useEffect(function () {
        store.fetchPage(1, 50);
    }, []);
    useEffect(function () {
        var _a;
        (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.scroll(0, 0);
    }, [page.index]);
    function handleFetch(page, size) {
        store.fetchPage(page, size);
    }
    function handleRefetch() {
        store.fetchPage(page.index, page.size);
    }
    return (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, { className: style.toolbar },
            React.createElement(Title, { style: style.title, title: 'Transacciones' })),
        React.createElement(PageContent, { className: style.content },
            React.createElement(OutlineCard, { className: style.outlineCard },
                React.createElement("div", { className: style.tableContainer },
                    React.createElement(Table, { refElement: tableRef, style: style.table },
                        React.createElement(TableHeader, { style: style.tableHeader },
                            React.createElement("span", null, "Comp."),
                            React.createElement("span", null, "N\u00FAmero"),
                            React.createElement("span", null, "Tipo"),
                            React.createElement("span", null, "Hora"),
                            React.createElement("span", null, "Monto"),
                            React.createElement("span", null, "Estado"),
                            React.createElement("span", null)),
                        React.createElement("tbody", null, page.transactions.map(function (transaction) {
                            return React.createElement(Fragment, { key: transaction.id },
                                transaction.product.type === 'Recarga' &&
                                    React.createElement(TopupRow, { transaction: transaction }),
                                transaction.product.type === 'Paquetico' &&
                                    React.createElement(DataPlanRow, { transaction: transaction }),
                                transaction.product.type === 'Pin' &&
                                    React.createElement(PinRow, { transaction: transaction }),
                                transaction.product.type === 'Factura' &&
                                    React.createElement(InvoiceRow, { transaction: transaction }));
                        }))),
                    page.successful && page.count === 0 &&
                        React.createElement(Empty, { image: emptyIcon, title: 'No hay datos para mostrar', description: 'Aun no ha realizado ventas' }),
                    page.error &&
                        React.createElement(Error, { className: style.error, onClick: handleRefetch })),
                React.createElement(Pagination, { title: 'Transacciones', index: page.index, pages: page.pages, size: page.size, count: page.count, onChange: handleFetch })))));
}
