var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import logo from '../../images/recargas-dominicanas.svg';
import serverErrorImage from '../../images/server-error.png';
import { Switch, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { BusinessApi } from '@recargas-dominicanas/core/api';
import { useOpenPopup } from '@recargas-dominicanas/core/hooks';
import { useAsyncEffect } from '@recargas-dominicanas/core/utils';
import { UserProfile } from '../../components/user-profile/user-profile.component';
import { SalesReports } from '../sales-reports/sales-reports.component';
import { Transactions } from '../transactions/transactions.component';
import { Settings } from '../settings/settings.component';
import { Home } from '../home/home.component';
import { style } from './main.module.css';
import { useBusiness, useBusinessUsers, useSalesReport, useProducts, useTransactions } from '@recargas-dominicanas/core/store';
import { ErrorPage, Header, Navbar, NavbarOption } from '@recargas-dominicanas/core/components';
export function Main() {
    var _this = this;
    var businessStore = useBusiness();
    var businessUserStore = useBusinessUsers();
    var salesReportStore = useSalesReport();
    var productsStore = useProducts();
    var transactionsStore = useTransactions();
    var business = businessStore.get();
    var businessUser = businessUserStore.get();
    var openProfile = useOpenPopup();
    var _a = useState(true), isLoading = _a[0], setLoading = _a[1];
    var _b = useState(false), error = _b[0], setError = _b[1];
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Promise.all([
                            businessStore.fetch(),
                            businessUserStore.fetch(),
                            productsStore.fetch(),
                            salesReportStore.fetchCurrent(),
                            salesReportStore.fetchPage(1, 50),
                            transactionsStore.fetchGroupByDay(1, 50),
                            transactionsStore.fetchPage(1, 50)
                        ])];
                case 1:
                    _b.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    setLoading(false);
                    setError(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); });
    useEffect(function () {
        initializeApp({
            apiKey: 'AIzaSyC7eigLI0OSSO97gpsbPn8DliEBWMjQXL4',
            authDomain: 'recargas-dominicanas.firebaseapp.com',
            projectId: 'recargas-dominicanas',
            storageBucket: 'recargas-dominicanas.appspot.com',
            messagingSenderId: '554293903687',
            appId: '1:554293903687:web:78fe32a30569eb8e800c62',
            measurementId: 'G-RGG4LZNK07'
        });
        var messaging = getMessaging();
        getToken(messaging, { vapidKey: process.env.FIREBASE_MESSAGING_KEY }).then(function (token) {
            if (token) {
                BusinessApi.addNotificationToken(token);
            }
            else {
                Notification.requestPermission();
            }
        });
        onMessage(messaging, function (payload) {
            var _a;
            if (((_a = payload.data) === null || _a === void 0 ? void 0 : _a.type) === 'BALANCE') {
                salesReportStore.fetchCurrent();
                alert("Balance asignado: ".concat(payload.data.balance));
            }
        });
        document.addEventListener('visibilitychange', function () {
            if (!document.hidden) {
                salesReportStore.fetchCurrent();
            }
        });
    }, []);
    useEffect(function () {
        var ticketWidth = localStorage.getItem('ticketWidth');
        var endLines = localStorage.getItem('endLines');
        if (!ticketWidth)
            localStorage.setItem('ticketWidth', '40');
        if (!endLines)
            localStorage.setItem('endLines', '5');
    }, []);
    if (isLoading) {
        return null;
    }
    if (error) {
        return (React.createElement(ErrorPage, { image: serverErrorImage, onClick: function () { return location.href = '/'; } }));
    }
    return (React.createElement("div", null,
        React.createElement(Header, { logo: logo, title: 'Recargas Dominicanas', name: "".concat(businessUser.name, ", ").concat(business.name), onOpenProfile: openProfile.handleOpen }),
        React.createElement("div", { className: style.body },
            React.createElement(Navbar, { style: style.navBar },
                React.createElement(NavbarOption, { align: 'top', icon: 'storefront', label: 'Inicio', route: '/' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'history', label: 'Transacciones', route: '/transacciones' }),
                React.createElement(NavbarOption, { align: 'top', icon: 'receipt_long', label: 'Cierres de venta', route: '/cierres-de-venta' }),
                React.createElement(NavbarOption, { align: 'bottom', icon: 'settings', label: 'Configuraci\u00F3n', route: '/configuracion' })),
            React.createElement("div", { className: style.content },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: '/', component: Home }),
                    React.createElement(Route, { exact: true, path: '/transacciones', component: Transactions }),
                    React.createElement(Route, { exact: true, path: '/cierres-de-venta', component: SalesReports }),
                    React.createElement(Route, { exact: true, path: '/configuracion', component: Settings })))),
        React.createElement(UserProfile, { open: openProfile.open, onClose: openProfile.handleClose, user: businessUser, business: business })));
}
