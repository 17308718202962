import React, { Fragment, useState } from 'react';
import { useBusiness } from '@recargas-dominicanas/core/store';
import { DataPlanModal } from '../data-plan-modal/data-plan-modal.component';
import { printDataPlan } from '../../utils/printDataPlan';
import { TransactionRow } from '../transaction-row/transaction-row.component';
export function DataPlanRow(props) {
    var transaction = props.transaction;
    var business = useBusiness().get();
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    return (React.createElement(Fragment, null,
        React.createElement(TransactionRow, { key: transaction.id, transaction: transaction, onClick: function () { return setOpenModal(true); }, onPrint: function () { return printDataPlan(business, transaction, undefined); } }),
        React.createElement(DataPlanModal, { open: openModal, title: 'Paquetico', transaction: transaction, onPrint: function () { return printDataPlan(business, transaction, undefined); }, onClose: function () { return setOpenModal(false); } })));
}
