import React, { useEffect, useRef } from 'react';
import emptyIcon from '../../images/receipt_long.svg';
import { useSalesReport } from '@recargas-dominicanas/core/store';
import { SalesReportRow } from '../../components/sales-report-row/sales-report-row.component';
import { Empty } from '../../components/empty/empty.components';
import { Error } from '../../components/error/error.component';
import { style } from './sales-reports.module.css';
import { OutlineCard, PageContainer, PageContent, PageToolbar, Pagination, Table, TableHeader, Title } from '@recargas-dominicanas/core/components';
export function SalesReports() {
    var store = useSalesReport();
    var page = store.getPage();
    var tableRef = useRef(null);
    useEffect(function () {
        store.fetchPage(1, 50);
    }, []);
    useEffect(function () {
        var _a;
        (_a = tableRef.current) === null || _a === void 0 ? void 0 : _a.scroll(0, 0);
    }, [page.index]);
    function handleFetch(page, size) {
        store.fetchPage(page, size);
    }
    function handleRefetch() {
        store.fetchPage(page.index, page.size);
    }
    return (React.createElement(PageContainer, { className: style.container },
        React.createElement(PageToolbar, { className: style.toolbar },
            React.createElement(Title, { style: style.title, title: 'Cierres de venta' })),
        React.createElement(PageContent, { className: style.content },
            React.createElement(OutlineCard, { className: style.outlineCard },
                React.createElement("div", { className: style.tableContainer },
                    React.createElement(Table, { refElement: tableRef, style: style.table },
                        React.createElement(TableHeader, { style: style.tableHeader },
                            React.createElement("span", null, "Fecha"),
                            React.createElement("span", null, "Balance"),
                            React.createElement("span", null, "Ventas"),
                            React.createElement("span", null, "Balance consumido"),
                            React.createElement("span", null, "Beneficio"),
                            React.createElement("span", null)),
                        React.createElement("tbody", null, page.salesReport.map(function (salesReport) {
                            return React.createElement(SalesReportRow, { key: salesReport.id, salesReport: salesReport });
                        }))),
                    page.successful && page.count === 0 &&
                        React.createElement(Empty, { image: emptyIcon, title: 'No hay datos para mostrar', description: 'Aun no ha realizado cierres de ventas' }),
                    page.error &&
                        React.createElement(Error, { className: style.error, onClick: handleRefetch })),
                React.createElement(Pagination, { title: 'Cierres de venta', index: page.index, pages: page.pages, size: page.size, count: page.count, onChange: handleFetch })))));
}
