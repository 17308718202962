import React, { Fragment, useState } from 'react';
import { useBusiness } from '@recargas-dominicanas/core/store';
import { TransactionRow } from '../transaction-row/transaction-row.component';
import { PinModal } from '../pin-modal/pin-modal.component';
import { printPin } from '../../utils/printPin';
export function PinRow(props) {
    var transaction = props.transaction;
    var business = useBusiness().get();
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    return (React.createElement(Fragment, null,
        React.createElement(TransactionRow, { key: transaction.id, transaction: transaction, onClick: function () { return setOpenModal(true); }, onPrint: function () { return printPin(business, transaction); } }),
        React.createElement(PinModal, { open: openModal, title: 'Pin', transaction: transaction, onPrint: function () { return printPin(business, transaction); }, onClose: function () { return setOpenModal(false); } })));
}
