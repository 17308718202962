import React from 'react';
import serverError from '../../images/server-error.png';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CreatePassword } from '../create-password/create-password.component';
import { Login } from '../login/login.component';
import { Main } from '../main/main.component';
import { NotFound } from '../not-found/not-found.component';
import { ErrorPage, ProtectedRoute } from '@recargas-dominicanas/core/components';
export function App() {
    return (React.createElement(BrowserRouter, null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: '/login', component: Login }),
            React.createElement(Route, { path: '/create-password/:token', component: CreatePassword }),
            React.createElement(Route, { path: '/page-not-found', component: NotFound }),
            React.createElement(Route, { path: '/error' },
                React.createElement(ErrorPage, { image: serverError })),
            React.createElement(ProtectedRoute, { path: '/', role: 'businessUser' },
                React.createElement(Main, null)),
            React.createElement(Route, { component: NotFound }))));
}
