// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container-rrCUi {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin: 0px auto;
  padding: 16px 0px;
}

.error-image-_cZ7B {
  width: 60px;
  height: 60px;
}

.error-title-GwOkl {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/error/error.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".container {\n  width: max-content;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 4px;\n  margin: 0px auto;\n  padding: 16px 0px;\n}\n\n.image {\n  width: 60px;\n  height: 60px;\n}\n\n.title {\n  color: rgba(0, 0, 0, 0.8);\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `error-container-rrCUi`,
	"image": `error-image-_cZ7B`,
	"title": `error-title-GwOkl`
};
export default ___CSS_LOADER_EXPORT___;
