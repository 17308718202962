// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../node_modules/@rmwc/circular-progress/circular-progress.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transactions-card-container-NPWfC {
  height: 100%;
  display: flex;
  flex: 0 0 360px;
  flex-direction: column;
  position: relative;
}

.transactions-card-title_container-ndQGX {
  padding: 16px 20px 12px;
}

.transactions-card-title_title-RxM6v {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.8);
}

.transactions-card-transactions-CtjUW {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-left: 8px;
}

.transactions-card-groupTitle_container-eRqgB {
  margin: 12px;
}

.transactions-card-groupTitle_title-nOhDa {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
}

.transactions-card-loading-gw7Of {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.transactions-card-circularProgress-Tn48j {
  display: block;
  margin: 12px auto;
}

.transactions-card-circularProgress-Tn48j .rmwc-circular-progress__path {
  stroke-width: 2px;
  color: rgba(0, 0, 0, 0.4);
}

.transactions-card-error-hM5uj {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1300px) {
  .transactions-card-container-NPWfC {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/transactions-card/transactions-card.module.css"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["@import url('@rmwc/circular-progress/circular-progress.css');\n\n.container {\n  height: 100%;\n  display: flex;\n  flex: 0 0 360px;\n  flex-direction: column;\n  position: relative;\n}\n\n.title_container {\n  padding: 16px 20px 12px;\n}\n\n.title_title {\n  font-size: 17px;\n  color: rgba(0, 0, 0, 0.8);\n}\n\n.transactions {\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  padding-left: 8px;\n}\n\n.groupTitle_container {\n  margin: 12px;\n}\n\n.groupTitle_title {\n  font-size: 16px;\n  color: rgba(0, 0, 0, 0.75);\n}\n\n.loading {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 12px;\n}\n\n.circularProgress {\n  display: block;\n  margin: 12px auto;\n}\n\n.circularProgress :global(.rmwc-circular-progress__path) {\n  stroke-width: 2px;\n  color: rgba(0, 0, 0, 0.4);\n}\n\n.error {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n@media (max-width: 1300px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `transactions-card-container-NPWfC`,
	"title_container": `transactions-card-title_container-ndQGX`,
	"title_title": `transactions-card-title_title-RxM6v`,
	"transactions": `transactions-card-transactions-CtjUW`,
	"groupTitle_container": `transactions-card-groupTitle_container-eRqgB`,
	"groupTitle_title": `transactions-card-groupTitle_title-nOhDa`,
	"loading": `transactions-card-loading-gw7Of`,
	"circularProgress": `transactions-card-circularProgress-Tn48j`,
	"error": `transactions-card-error-hM5uj`
};
export default ___CSS_LOADER_EXPORT___;
